
@font-face {
  font-family: 'DIN';
  src: url('/fonts/NunitoSans-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'DIN';
  src: url('/fonts/NunitoSans-Light.ttf');
  font-weight: 300;
}

body {
  margin: 0;
  padding: 0;
  font-family:"DIN";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
