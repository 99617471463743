.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

table {
  border-top: 1px solid black;    /* Just to Highlight Top of Table */
  margin: 20
}
table thead tr {
  height: 200px;    /* Fixed Row Height */
}

/* Flex Table */
table.flex-table {
  display: flex;
  flex-direction: column;
  height: 100%;
}
table.flex-table thead,
table.flex-table tbody {
  display: block;
}
table.flex-table thead {
}
table.flex-table tbody {
  flex: 1;
}
table.flex-table tr {
  display: flex;
  height: auto;
  margin-right: 5px
}
table.flex-table tr td,
table.flex-table tr th {
  display: flex;
  flex: 1;
  align-items:center;
  justify-content:center;
}

table.flex-table tr:nth-child(2n+2) {
  background: #5e466c13;
}


.ribbon {
  position: absolute;
  right: -5px; top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px; height: 75px;
  text-align: right;
}
.ribbon span {
  font-size: 10px;
  font-weight: bold;
  color: #000;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 100px;
  display: block;
  background: #79A70A;
  background: linear-gradient(#FFFF1E 0%, #FFFF1E 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 19px; right: -21px;
}
.ribbon span::before {
  content: "";
  position: absolute; left: 0px; top: 100%;
  z-index: -1;
  border-left: 3px solid #C1CC2B;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #C1CC2B;
}
.ribbon span::after {
  content: "";
  position: absolute; right: 0px; top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid #C1CC2B;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #C1CC2B;
}